<template>
  <div>
    <ActivityBan :title="title" />
    <ActivityInfo />
    <ActivityDetail />
  </div>
</template>

<script>
import ActivityBan from '../components/activity/ActivityBan.vue';

import ActivityInfo from '@/components/activity/activity-info'
import ActivityDetail from '@/components/activity/activity-detail'
export default {
  components: {
    ActivityBan,
    ActivityInfo,
    ActivityDetail
  },
  data() {
    return {
      title: this.$t('header.activity')
    }
  }
}
</script>
<style scoped lang="scss"></style>