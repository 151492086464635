<template>
  <div class="activity">
    <h2>{{ $t("activity.title") }}</h2>

    <div class="activity-info">
      <h3>{{ $t("activity.service") }}</h3>
      <p>{{ $t("activity.describe") }}</p>
      <button class="common-but active" @click="$open('partake', $i18n.locale)">{{ $t("activity.participate")
        }}</button>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>
<style scoped lang="scss">
h2 {
  font-family: MicrosoftYaHeiUI-Bold;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  padding: 60px 0 82px 0;
  text-align: center;
}

.activity-info {
  background: url(../../assets/img/activity/activity.png) no-repeat center center;
  background-size: 100% 100%;
  width: 1038px;
  height: 331px;
  margin: 0 auto;

  h3 {
    width: 546px;
    height: 63px;
    font-family: MicrosoftYaHeiUI-Bold;
    font-size: 24px;
    font-weight: bold;
    line-height: 37px;
    color: #ffffff;
    padding: 64px 0 0 41px;
    box-sizing: content-box;
  }

  p {
    width: 435px;
    height: 44px;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    color: #ffffff;
    text-align: center;
    margin: 21px 0 0 96px;
  }

  button {
    margin: 38px 0 0 240px;
  }
}

@media screen and (max-width: 992px) {
  h2 {
    font-size: 15px;
    padding: 40px 0;
  }
  .activity-info {
    width: 95vw;
    padding: 10px 15px;
    height: 200px;
    background-size: cover;
    background-position: center center;

    h3 {
      width: 80%;
      line-height: 20px;
       
      color: #ffffff;
      font-size: 15px;
      font-weight: 600;
      margin-top: 20px;
      padding:0;
      height: auto;
    }

    p {
      width: 80%;
      height: auto;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 18px;
      margin-top: 10px;
      text-align: left;
      margin-left:0;
    }

    .common-but {
      height: 30px;
      line-height: 30px;
      margin: 0;
      margin-top:20px;
    }
  }
}
</style>